<script setup lang="ts">
import IconLogo from '@/components/icons/IconLogo.vue'
import Contact from '@/components/contact/Contact.vue'
import TopMenu from '@/components/menu/TopMenu.vue'
import { useHead, useSeoMeta } from '@unhead/vue'
import { useRoute } from 'vue-router'

const title = 'Camilla - A digital playground'
const description = '🎮 Camilla´s digital dev playground'

const url = useRequestURL()
const route = useRoute()
const domain = url.host

useHead({
  title: title
})

useSeoMeta({
  title: title,
  description: description,
  ogTitle: () => title,
  ogDescription: () => description,
  ogImage: () => `https://${domain}/imgs/og-bunny.png`,
  ogUrl: () => `https://${domain}${route.path}`,
  ogType: () => "website"
})
</script>
<template>
  <div>
    <TopMenu />
    <header
      class="w-full overflow-hidden z-50 absolute top-0 left-0 px-4 py-4 lg:px-6 lg:py-6 flex flex-row"
    >
      <div class="self-end flex-1 flex justify-end">
        <div
          class="z-20 text-secondary w-12 lg:w-14 h-12 lg:h-14 md:w-14 md:h-14 group relative overflow-visible"
        >
          <a href="/" class="relative z-20"><IconLogo /></a>
          <div
            class="absolute z-0 opacity-0 group-hover:opacity-100 w-6 h-6 md:w-7 md:h-7 bg-secondary shadow-glowing rounded-2xl top-1/3 left-1/3 -mt-2 -ml-1"
          ></div>
        </div>
      </div>
    </header>
    <main id="top" class="bg-gradient h-full min-h-screen relative scroll-smooth">
      <slot></slot>
    </main>
    <Contact />
  </div>
</template>
