<template>
<svg viewBox="-4.64 -4.64 67.28 67.28" fill="transparent" stroke="none" stroke-width="0.00058" xmlns="http://www.w3.org/2000/svg">
  <defs/>
  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" fill="none" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="4.292">
    <g>
      <polygon style="fill:#26B99A;" points="29,58 3,45 3,13 29,26 "/>
      <polygon style="fill:#26B99A;" points="29,58 55,45 55,13 29,26 "/>
      <polygon style="fill:#26B99A;" points="3,13 28,0 55,13 29,26 "/>
    </g>
  </g>
  <g id="SVGRepo_iconCarrier">
    <g>
      <polygon style="fill: rgb(31, 147, 122);" points="29,58 3,45 3,13 29,26 "/>
      <polygon style="fill: rgb(64, 209, 179);" points="29,58 55,45 55,13 29,26 "/>
      <polygon style="fill:#26B99A;" points="3,13 28,0 55,13 29,26 "/>
    </g>
  </g>
  <path d="M 14.08 43.28 Q 12.32 43.28 11.05 42.57 Q 9.77 41.85 8.95 40.6 Q 8.12 39.36 7.74 37.78 Q 7.36 36.19 7.42 34.49 Q 7.53 31.43 8.54 29.22 Q 9.55 27.01 11.09 25.58 Q 12.63 24.15 14.41 23.47 Q 16.18 22.78 17.84 22.78 Q 19.24 22.78 20.37 23.24 Q 21.5 23.71 22.18 24.59 Q 22.85 25.47 22.85 26.73 Q 22.85 27.71 22.27 28.62 Q 21.7 29.53 20.54 30.1 Q 19.38 30.68 17.56 30.68 Q 17.86 29.78 18 29.11 Q 18.14 28.44 18.14 27.96 Q 18.14 26.98 17.7 26.63 Q 17.25 26.28 16.58 26.28 Q 15.76 26.28 15.04 26.87 Q 14.31 27.46 13.73 28.45 Q 13.16 29.45 12.82 30.65 Q 12.49 31.85 12.43 33.09 Q 12.32 36.19 13.33 37.79 Q 14.34 39.39 15.93 39.39 Q 17.16 39.39 18.66 38.38 Q 20.16 37.37 21.62 35.33 L 21.98 35.49 Q 21.62 38.01 20.38 39.75 Q 19.15 41.49 17.49 42.38 Q 15.82 43.28 14.08 43.28 Z" transform="matrix(1.283945, 0.65874, 0, 1.074707, -3.506289, -9.598593)" style="fill: rgb(255, 255, 255);"/>
  <path d="M 40.92 44.21 L 37.5 29.04 L 36.01 29.04 L 36.01 24.8 L 38.95 24.8 L 42.37 39.97 L 43.28 39.97 L 43.28 44.21 Z M 33.52 44.21 L 33.52 24.8 L 36.99 24.8 L 36.99 44.21 Z M 42.87 44.21 L 42.87 24.8 L 46.34 24.8 L 46.34 44.21 Z" transform="matrix(1.410671, -0.696451, 0, 0.930367, -14.495696, 31.484756)" style="fill: rgb(255, 255, 255); stroke: rgb(255, 255, 255);"/>
</svg>
</template>